import React from "react";
import styled from 'styled-components';

import { Container, Row, Col } from 'styled-bootstrap-grid';

import Button from "./button";

const H1 = styled.h1 `
  padding-bottom: 1rem;

  font-size: 1.5rem;

  @media screen and (min-width: 768px) {
    font-size: 1.75rem;
  }
`;

const SubTitle = styled.div `
  margin-bottom: 2rem;

  font-size: 1rem;
  line-height: 1.125rem;

  @media screen and (min-width: 768px) {
    font-size: 1rem;
    line-height: 1.25rem;
  }
`;

const Action = styled.div `
  text-align: left;

  margin-left: -0.5rem;
`;

const HeroImage = styled.img`
  height: auto;
  max-width: 100%;
  width: 100%;
`;

const HeroDouble = ( props ) => {

  return (
    <Container>
      <Row alignItems="center">
        <Col col xl="7" lg="7" md="12" sm="12" xs="12">
          {props.bloc.title && (
            <H1>{props.bloc.title}</H1>
          )}

          {props.bloc.subtitle && (
            <SubTitle dangerouslySetInnerHTML={{__html: props.bloc.subtitle}} />
          )}

          {(props.bloc.button_primary || props.bloc.button_secondary) && (
            <Action>
              {props.bloc.button_primary && (
                <Button content={props.bloc.button_primary} popup={props.site.popup} />
              )}
              {props.bloc.button_secondary && (
                <Button content={props.bloc.button_secondary} popup={props.site.popup} />
              )}
            </Action>
          )}
        </Col>

        {props.bloc.media && (
          <Col col xl="5" lg="5" xs="12">
            <HeroImage
              alt={"hero_image"}
              src={process.env.NODE_ENV !== "development" ? props.bloc.media.url : process.env.REACT_APP_BACKEND_URL + props.bloc.media.url}
            />
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default HeroDouble;
