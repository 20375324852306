import React from "react";
import styled from 'styled-components'

import { Container, Row, Col } from 'styled-bootstrap-grid';

import Button from "./button";

const SubTitle = styled.div`
  padding: 32px 0 12px;

  color: ${bloc => bloc.txtColor || "#fff"};
  font-size: 16px;
  font-weight: 800;
  line-height: 20px;
  text-transform: uppercase;
`;

const Title = styled.h2`
  padding-bottom: 16px;

  font-size: 32px;
  font-weight: 800;
  line-height: 42px;
`;

const Description = styled.div`
  font-size: 16px;
  line-height: 20px;
`;

const ButtonWrapper = styled.div`
  margin-left: -0.5rem;
  padding-top: 1rem;
`;

const HeroImage = styled.div`
  background-repeat: noe-repeat;
  background-size: cover;
  display: flex;

  height: 240px;
  max-width: 100%;

  @media screen and (min-width: 992px) {
    height: 340px;
  }
`;

const Split = ({ site, bloc }) => {

  return (
    <Container>
      <Row>

        {(bloc.direction === "left_image" && bloc.image) && (
          <Col col={12} sm={6}>
            <HeroImage
              style={{backgroundImage: `url(${process.env.NODE_ENV !== "development" ? bloc.image.url : process.env.REACT_APP_BACKEND_URL + bloc.image.url})`}}
            />
          </Col>
        )}
        <Col col={12} xs={12} sm={6} md={6} lg={6} xl={6} order={1} smOrder={0}>
          <div>
          {bloc.subtitle && (
            <SubTitle txtColor={site.config.color_secondary}>{bloc.subtitle}</SubTitle>
          )}
          {bloc.title && (
            <Title>{bloc.title}</Title>
          )}
          {bloc.description && (
            <Description>
              <div dangerouslySetInnerHTML={{ __html: bloc.description }} />
            </Description>
          )}

          {bloc.button && (
            <ButtonWrapper>
              <Button content={bloc.button} />
            </ButtonWrapper>
          )}
          </div>
        </Col>

        {(bloc.direction === "right_image" && bloc.image) && (
          <Col col={12} xs={12} sm={6} md={6} lg={6} xl={6} order={0} smOrder={1}>
            <HeroImage
              style={{backgroundImage: `url(${process.env.NODE_ENV !== "development" ? bloc.image.url : process.env.REACT_APP_BACKEND_URL + bloc.image.url})`}}
            />
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default Split;
