import React, { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import ReactGA from 'react-ga';
import GA4React from "ga-4-react";

import Query from "../../components/Query";
import PAGES_QUERY from "../../queries/pages";

import Site from "../Site";
import FourZeroFour from "../FourZeroFour";



function App() {

  // To remove Trailing slash
  const pathname = window.location.pathname;

  if (process.env.REACT_APP_GA_VERSION === "v4") {
    const ga4react = new GA4React(process.env.REACT_APP_GA_UA_ID);

    ga4react.initialize().then((ga4) => {
      ga4.pageview('path')
      ga4.gtag('event','pageview','path') // or your custom gtag event
    },(err) => {
      console.error(err)
    });
  }

  if (process.env.REACT_APP_GA_VERSION !== "v4") {
    ReactGA.initialize({
      trackingId: process.env.REACT_APP_GA_UA_ID
    });
  }

  useEffect( () => {
    // This line will trigger on a route change
    if (process.env.REACT_APP_GA_VERSION !== "v4") {
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  });

  return (
    <Query query={PAGES_QUERY} id={process.env.REACT_APP_SITE_ID}>
      {({ data: { pages } }) => {

        function rewriteRoute(slug) {
          let stg = slug;
          let path = slug;

          if (slug === "home" || slug === "/" || slug === null) {
            path = "/"
          }
          else {
            stg = "/" + slug + "/"
            path = stg.slice(0, -1)
          }
          return path
        }

        return (
          <Switch>
            <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
            {pages.map((page, index) => (
              <Route exact path={rewriteRoute(page.slug)} component={Site} key={index}/>
            ))}
            <Route component={FourZeroFour} />
          </Switch>
        )
      }}
      </Query>
  );
}

export default App;
